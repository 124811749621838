import { Image, ImageProps, Box, keyframes } from "@chakra-ui/react";
import StoryModeModal, {
  StoryModeModalContext,
  withStoryModeModal,
} from "../StoryModeModal";
import { useDialogWithOptions } from "../../../Common/Dialog";
import { KYDStoryAsset } from "@common/interfaces/KYDEvent";
import { analytics } from "../../../utils";
import { KYDStory } from "@common/interfaces/KYDEvent";

interface EventImageProps extends Omit<ImageProps, "src"> {
  posterImage?: string;
  story?: KYDStory;
}

function EventImage({ posterImage, story, ...props }: EventImageProps) {
  const storyModeDialog = useDialogWithOptions(StoryModeModalContext);

  return (
    <Box
      onClick={() => {
        analytics.t("open_story_click", {
          story_count: story?.items.length,
        });
        story?.items.length && storyModeDialog.open();
      }}
      cursor={story?.items.length ? "pointer" : "default"}
      {...props}
    >
      <StoryModeModal
        storyAssets={story?.items}
        width={["95vw", 432]}
        height={768}
      />
      {story?.items.length ? (
        <div
          className="card example-5"
          style={{
            borderRadius: "10px",
            position: "relative",
            overflow: "hidden",
          }}
        >
          <svg height="100%" width="100%" xmlns="http://www.w3.org/2000/svg">
            <rect rx="10" ry="10" className="line" height="100%" width="100%" />
          </svg>
          <div className="inner">
            <Box padding="6px" maxW="200px" maxH="200px">
              <Image objectFit="cover" rounded={"md"} src={posterImage} />
            </Box>
          </div>
        </div>
      ) : (
        <Box padding="6px" maxW="200px" maxH="200px">
          <Image objectFit="cover" rounded={"md"} src={posterImage} />
        </Box>
      )}
    </Box>
  );
}

export default withStoryModeModal(EventImage);
